<script setup lang="ts">
withDefaults(
  defineProps<{
    errorMessages?: string | string[];
    readonly?: boolean;
    disabled?: boolean;
    hideDetails?: boolean | 'auto';
    label?: string;
    placeholder?: string;
    maskaOption?: Object | null;
  }>(),
  {
    errorMessages: () => [],
    readonly: false,
    disabled: false,
    hideDetails: false,
    label: '',
    placeholder: '',
    maskaOption: null,
  }
);
const text = defineModel<string | number | null>();
</script>
<template>
  <v-text-field
    v-maska="maskaOption"
    v-model="text"
    flat
    variant="solo-filled"
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    :label="label"
    :readonly="readonly"
    :disabled="disabled"
    data-testid="text-field"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
  </v-text-field>
</template>

<style></style>
